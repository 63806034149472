import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "about",

    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/learn/learn",
    name: "learn",

    component: () => import("../views/learn.vue"),
  },
  {
    path: "/learn/exam",
    name: "exam",

    component: () => import("../views/exam.vue"),
  },
  {
    path: "/learn/evaluate",
    name: "evaluate",

    component: () => import("../views/evaluate.vue"),
  },
  {
    path: "/contactUs",
    name: "contactUs",
    component: () => import("../views/contactUs.vue"),
  },
  {
    path: "/learn/price",
    name: "price",
    component: () => import("../views/price.vue"),
  },
  {
    path: "/oneplusx",
    name: "oneplusx",
    component: () => import("../views/oneplusx.vue"),
  },
  {
    path: "/hr/standardization",
    name: "standardization",
    component: () => import("../views/standardization.vue"),
  },
  {
    path: "/hr/skillEvaluate",
    name: "skillEvaluate",
    component: () => import("../views/skillEvaluate.vue"),
  },
  {
    path: "/professionalStandards",
    name: "professionalStandards",
    component: () => import("../views/professionalStandards.vue"),
  },

  {
    path: "/financialecurity",
    name: "financialecurity",
    component: () => import("../views/financialecurity.vue"),
  },
  {
    path: "/healthcare",
    name: "healthcare",
    component: () => import("../views/healthCare.vue"),
  },
  {
    path: "/doctor",
    name: "doctor",
    component: () => import("../views/doctor.vue"),
  },
  {
    path: "/learn/certificateManagement",
    name: "certificateManagement",
    component: () => import("../views/certificateManagement.vue"),
  },
  {
    path: "/learn/startTest",
    name: "startTest",
    component: () => import("../views/startTest.vue"),
  },
  {
    path: "/learn/continuEducation",
    name: "continuEducation",
    component: () => import("../views/continuEducation.vue"),
  },
  {
    path: "/asideText",
    name: "asideText",
    component: () => import("../views/asideText.vue"),
  },
  {
    path: "/creditPage1",
    name: "creditPage1",
    component: () => import("../views/creditPage1.vue"),
  }, {
    path: "/creditPage2",
    name: "creditPage2",
    component: () => import("../views/creditPage2.vue"),
  }, {
    path: "/creditPage3",
    name: "creditPage3",
    component: () => import("../views/creditPage3.vue"),
  }, {
    path: "/creditPage4",
    name: "creditPage4",
    component: () => import("../views/creditPage4.vue"),
  }, {
    path: "/creditPage5",
    name: "creditPage5",
    component: () => import("../views/creditPage5.vue"),
  }, {
    path: "/creditPage6",
    name: "creditPage6",
    component: () => import("../views/creditPage6.vue"),
  },
  {
    path: "/loginPage",
    name: "loginPage",
    component: () => import("../views/loginPage.vue"),
  },
  {
    path: "/smartMarketing",
    name: "smartMarketing",
    component: () => import("../views/smartMarketing.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
