<template>
  <div class="totalContainer">
    <div> <img
        src="@/assets/dt.gif"
        alt=""
        style="width: 100%; display: block"/></div>

      <div class="contentBox leftText">
          <div class="bigFont greenFont">智慧金融</div>
<!--          <div class="bigFont greenFont">OUR BUSINESS</div>-->
          <br />
          <div class="betweenFlex centerText">
              <div class="flex1">
                  <img class="squ"
                          src="@/assets/zhjr1.jpg"
                          alt=""
                          style="width: 100%; display: block"
                  />
                  <br />
                  <div class="midFont greenFont">我是金融机构</div>
                  <p class="contentFont tx">
                      我想定制风险控制系统
                  </p>
                  <p class="contentFont tx">
                      我想定制服务质检系统
                  </p>
                  <p class="contentFont tx">
                      我想管理我的门店/客户
                  </p>
              </div>
              <div class="flex1">
                  <img
                      class="squ"
                          src="@/assets/zhjr2.jpg"
                          alt=""
                          style="width: 100%; display: block"
                  />
                  <br />
                  <div class="midFont greenFont ">我是企业</div>
                  <p class="contentFont tx" @click="goTo('/creditPage1')">
                      我想配对信贷产品
                  </p>
                  <p class="contentFont tx">
                      我想学习企业管理
                  </p>
                  <p class="contentFont tx">
                      我想做财务健康体检
                  </p>

              </div>
              <div class="flex1">
                  <img
                      class="squ"
                          src="@/assets/zhjr3.jpg"
                          alt=""
                          style="width: 100%; display: block"
                  />
                  <br />
                  <div class="midFont greenFont">我是企业服务商</div>
                  <p class="contentFont tx">
                      我想加入裕康生态
                  </p>
                  <p class="contentFont tx">
                      我想寻找融资项目
                  </p>
                  <p class="contentFont tx">
                      我想管理我的产品
                  </p>
              </div>
          </div>
      </div>
      <div class="betweenFlex" style="width: 70%; margin: auto">
          <div class="dividedContainer flex1">
              <div class="line"></div>
              <div class="smallFont greyFont font centerText contentFont tx" @click="toContactUs()">联系智慧金融专员</div>
              <div class="line"></div>
          </div>
      </div>
<!--      <div class="contentBox leftText">-->
<!--          <div class="bigFont greenFont">智慧健康</div>-->
<!--&lt;!&ndash;          <div class="bigFont greenFont">OUR BUSINESS</div>&ndash;&gt;-->
<!--          <br />-->
<!--          <div class="betweenFlex centerText">-->

<!--              <div class="flex1">-->
<!--                  <img-->
<!--                      class="squ"-->
<!--                          src="@/assets/zhjk3.jpg"-->
<!--                          alt=""-->
<!--                          style="width: 100%; display: block"-->
<!--                  />-->
<!--                  <br />-->
<!--                  <div class="midFont greenFont">我是孕妈</div>-->
<!--                  <a href="http://www.p2ifamily.com/maternity_p2i">-->
<!--                  <p class="contentFont tx">-->
<!--                      我想了解孕前健康管理-->
<!--                  </p>-->
<!--                  </a>-->
<!--                  <a href="http://www.p2ifamily.com">-->
<!--                  <p class="contentFont tx">-->
<!--                      我想了解孕中健康管理-->
<!--                  </p>-->
<!--                  </a>-->
<!--                  <a href="http://www.p2ifamily.com">-->
<!--                  <p class="contentFont tx">-->
<!--                      我想了解孕后健康管理-->
<!--                  </p>-->
<!--                  </a>-->
<!--              </div>-->
<!--              <div class="flex1">-->
<!--                  <img-->
<!--                      class="squ"-->
<!--                      src="@/assets/zhjk2.jpg"-->
<!--                      alt=""-->
<!--                      style="width: 100%; display: block"-->
<!--                  />-->
<!--                  <br />-->
<!--                  <div class="midFont greenFont">我是宝妈</div>-->
<!--                  <a href="http://www.p2ifamily.com">-->
<!--                  <p class="contentFont tx">-->
<!--                      我想了解心理健康管理-->
<!--                  </p>-->
<!--                  </a>-->
<!--                  <a href="http://www.p2ifamily.com">-->
<!--                  <p class="contentFont tx">-->
<!--                      我想了解睡眠健康管理-->
<!--                  </p>-->
<!--                  </a>-->
<!--                  <a href="http://www.p2ifamily.com/brain_development">-->
<!--                  <p class="contentFont tx">-->
<!--                      我想了解脑功能健康管理-->
<!--                  </p>-->
<!--                  </a>-->
<!--              </div>-->
<!--              <div class="flex1">-->
<!--                  <img-->
<!--                      class="squ"-->
<!--                          src="@/assets/zhjk1.jpg"-->
<!--                          alt=""-->
<!--                          style="width: 100%; display: block"-->
<!--                  />-->
<!--                  <br />-->
<!--                  <div class="midFont greenFont">我是长者</div>-->
<!--                  <p class="contentFont tx">-->
<!--                      我想了解心理健康管理-->
<!--                  </p>-->
<!--                  <p class="contentFont tx">-->
<!--                      我想了解睡眠健康管理-->
<!--                  </p>-->
<!--                  <a href="http://www.p2ifamily.com/brain_degeneration">-->
<!--                  <p class="contentFont tx">-->
<!--                    我想了解脑功能健康管理-->
<!--                  </p>-->
<!--                  </a>-->
<!--              </div>-->
<!--          </div>-->
<!--      </div>-->
<!--      <div class="betweenFlex" style="width: 70%; margin: auto">-->
<!--          <div class="dividedContainer flex1">-->
<!--              <div class="line"></div>-->
<!--              <div class="smallFont greyFont font centerText contentFont tx" @click="toContactUs()">联系智慧健康专员</div>-->
<!--              <div class="line"></div>-->
<!--          </div>-->
<!--      </div>-->
      <div class="contentBox leftText">
          <div class="bigFont greenFont">智慧营销</div>
          <!--          <div class="bigFont greenFont">OUR BUSINESS</div>-->
          <br />
          <div class="betweenFlex centerText">

              <div class="flex1">
                  <img
                      class="squ"
                      src="@/assets/zhyx1.jpeg"
                      alt=""
                      style="width: 100%; display: block"
                  />
                  <br />
                  <div class="midFont greenFont">我从事文化创意产业</div>
                  <a href="http://www.p2ifamily.com/maternity_p2i">
                      <p class="contentFont tx">
                          我想了解游戏营销
                      </p>
                  </a>
                  <a href="http://www.p2ifamily.com">
                      <p class="contentFont tx">
                          我想了解影视营销
                      </p>
                  </a>
                  <a href="http://www.p2ifamily.com">
                      <p class="contentFont tx">
                          我想了解教育营销
                      </p>
                  </a>
              </div>
              <div class="flex1">
                  <img
                      class="squ"
                      src="@/assets/zhyx2.jpg"
                      alt=""
                      style="width: 100%; display: block"
                  />
                  <br />
                  <div class="midFont greenFont">我从事创新技术产业</div>
                  <a href="http://www.p2ifamily.com">
                      <p class="contentFont tx">
                          我想了解APP营销
                      </p>
                  </a>
                  <a href="http://www.p2ifamily.com">
                      <p class="contentFont tx">
                          我想了解AI算法营销
                      </p>
                  </a>
                  <a href="http://www.p2ifamily.com/brain_development">
                      <p class="contentFont tx">
                          我想了解科技专利营销
                      </p>
                  </a>
              </div>
              <div class="flex1">
                  <img
                      class="squ"
                      src="@/assets/zhyx3.jpeg"
                      alt=""
                      style="width: 100%; display: block"
                  />
                  <br />
                  <div class="midFont greenFont">我从事实体商品产业</div>
                  <p class="contentFont tx">
                      我想了解国内新媒体营销
                  </p>
                  <p class="contentFont tx">
                      我想了解海外新媒体营销
                  </p>
                  <a href="http://www.p2ifamily.com/brain_degeneration">
                      <p class="contentFont tx">
                          我想了解海内外新媒体营销
                      </p>
                  </a>
              </div>
          </div>
      </div>
      <div class="betweenFlex" style="width: 70%; margin: auto">
          <div class="dividedContainer flex1">
              <div class="line"></div>
              <div class="smallFont greyFont font centerText contentFont tx" @click="toContactUs()">联系智慧营销专员</div>
              <div class="line"></div>
          </div>
      </div>
      <div class="contentBox leftText">
          <div class="bigFont greenFont">智慧人力</div>
<!--          <div class="bigFont greenFont">OUR BUSINESS</div>-->
          <br />
          <div class="betweenFlex centerText">

              <div class="flex1">
                  <img
                      class="squ"
                          src="@/assets/zhrl3.jpg"
                          alt=""
                          style="width: 100%; display: block"
                  />
                  <br />
                  <div class="midFont greenFont">我是社会组织</div>
                  <p class="contentFont tx">
                      我想开发职业技能标准
                  </p>
                  <p class="contentFont tx">
                      我想定制行业人才管理系统
                  </p>
                  <p class="contentFont tx">
                      我想参加行业评价认定
                  </p>

              </div>
              <div class="flex1">
                  <img
                      class="squ"
                      src="@/assets/rlzy3.jpg"
                      alt=""
                      style="width: 100%; display: block"
                  />
                  <br />
                  <div class="midFont greenFont">我是企业</div>
                  <p class="contentFont tx">
                      我想定制岗位胜任力测评
                  </p>
                  <p class="contentFont tx">
                      我想定制企业人力管理系统
                  </p>
                  <p class="contentFont tx">
                      我想了解1+X证书管理体系
                  </p>
              </div>
              <div class="flex1">
                  <img
                      class="squ"
                          src="@/assets/zhrl4.jpg"
                          alt=""
                          style="width: 100%; display: block"
                  />
                  <br />
                  <div class="midFont greenFont">我是追梦者</div>
                  <p class="contentFont tx">
                      我想就业
                  </p>
                  <p class="contentFont tx">
                      我想创业
                  </p>
                  <p class="contentFont tx">
                      我想学习职业技能课程
                  </p>
              </div>
          </div>
      </div>
    <div class="betweenFlex" style="width: 70%; margin: auto">
      <div class="dividedContainer flex1">
        <div class="line"></div>
        <div class="smallFont greyFont font centerText contentFont tx" @click="toContactUs()">联系智慧人力专员</div>
         <div class="line"></div>
      </div>
    </div>
<!--    <div class="contentBox leftText">-->
<!--      <div class="bigFont greenFont">关于我们</div>-->
<!--      <div class="bigFont greenFont">ABOUT US</div>-->
<!--      <p class="aboutFont">-->
<!--        裕康云科技（深圳）有限公司（简称：裕康云），专注于数字技术创新、服务创新，为金融机构、医疗机构、社会团体、高职院校以及规模型企业提供专业的系统开发、平台代运营、SaaS服务等综合性云技术运营方案。-->
<!--      </p>-->
<!--      <p class="aboutFont">-->
<!--        我们从事AIoT（AI+IoT）解决方案的研发与推广，运用AI技术及物联网技术，帮助各行业进行产业智能升级，提升经营管理水平，降低建造和维护成本，推进社会智能化发展。-->
<!--      </p>-->
<!--      <p class="aboutFont">-->
<!--        我们主要业务集中在智慧金融、智慧医疗、智慧教育等领域，未来将深入参与智慧物业、智慧养老、智慧安防、数字治理等生活密切相关的领域。-->
<!--      </p>-->
<!--      <p class="aboutFont">-->
<!--        为了满足AIoT在众多实际应用场景中 “感知-传输-分析-决策-应用-衍进”-->
<!--        全链条的融合,我们提供 “服务质检平台 YUKON&ensp;SYSTEM&ensp;MANAGEMENT” 、-->
<!--        “倍护爱院外健康监测系统 P2i&ensp;FAMILY&ensp;CARE” 、 “学考评台 YUKON&ensp;ASSESSMENT”-->
<!--        三个业内先进的平台，最大程度提升平台价值的交叉协同性。在专注于科技金融,医疗健康,人力资源等领域的同时裕康云致力于技术赋能多行业发展。-->
<!--      </p>-->
<!--      <p class="aboutFont">-->
<!--        总部位于中国深圳，并在北京、上海、广州等地拥有分公司和多家战略合作伙伴。-->
<!--      </p>-->
<!--      <br />-->
<!--      <img-->
<!--        src="@/assets/mainpageaboutimg.jpg"-->
<!--        alt=""-->
<!--        style="display: block; width: 100%; margin: auto"-->
<!--      />-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
    data() {

    },
    mounted() {},
    methods: {
        toContactUs(){
            this.$router.push('/contactUs');
        },
        goTo(e){
            this.$router.push(e)
        },
    },
};
</script>
<style scoped lang="less">
.dividedContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .line {
    height: 2px;
    background: #ccc;
    flex: 2;
  }
  .font {
    flex: 1;
    margin: 10px;
    transform: scale(0.8);
  }
}
.totalContainer {
  color: #333;
    margin-bottom: 100px;
}
.betweenFlex .flex1:not(:last-child) {
  margin-right: 50px;
}
.contentBox {
  padding: 30px 0;
  width: 70%;
  margin: 100px auto auto;

}

.centerText {
  text-align: center;
}

.contentFont {
  //width: 85%;
  line-height: 1.6em;
    font-size: 1.1em;
}
.contentFont:hover {
    //width: 85%;
    cursor: pointer;
   // color: #3eac66;
    color: #3eac66;
}
.aboutFont {
  line-height: 1.6em;
  margin: 30px 0;
}
.squ {
    cursor: pointer;
    width: 30%;
    transition: 0.3s all;

    &:hover {
        transition-duration: 300ms;
        transform: translateY(-10px);
    }
}
</style>